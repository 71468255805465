import http from "../../../../services/http-common";

class financialService {
  // eslint-disable-next-line class-methods-use-this
  async billingClose(data: any) {
    try {
      return http.get(
        `/customer/financial/close/${data.initialSP}/${data.initialPA}/${data.initialNE}`
      );
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(offset: string, limit: string, params: any) {
    return http.get(`billing/financial/close/${offset}/${limit}`, {
      params: params,
    });
  }

  getAllOrders(idBilling: string) {
    return http.get(`billing/financial/report/${idBilling}`);
  }
}

export default new financialService();
