/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from "react";
import * as Yup from "yup";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
  FieldArray,
} from "formik";
import {
  Card,
  Grid,
  TextField,
  FormControl,
  CardHeader,
  Select,
  MenuItem,
  Container,
  Typography,
  InputLabel,
  CardContent,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  InputAdornment,
} from "@mui/material";

import { useData } from "../../../../contexts/client";
import productService from "../../products/services/productService";

export const COMPANIES = [
  { name: "PA - Porto Alegre", value: "PA" },
  { name: "NE - Fortaleza", value: "NE" },
  { name: "SP - São Paulo", value: "SP" },
];

export default function FormGroup(props: any) {
  const { state }: any = useLocation();
  const [checked, setChecked] = useState(false);
  const [onRegister, setOnRegister] = useState(false);
  const [productData, setProductData]: any = useState([]);
  const [productDataAux, setProductDataAux]: any = useState([]);
  const [productPriceData, setProducPricetData]: any = useState({
    name_Lens: "default",
    id_Lens: "",
  });

  const { handleGroupData } = useData();

  const RegisterSchema = Yup.object().shape({
    // Client Data

    name_Team: Yup.string().required("Nome do grupo é obrigatório"),
    firstRange_Team: Yup.number().required("Faixa de volume 1 é obrigatória"),
    secondRange_Team: Yup.number()
      .moreThan(
        Yup.ref("firstRange_Team"),
        "Faixa de volume inferior a primeira"
      )
      .required("Faixa de volume 2 é obrigatória"),
    observations_Customer: Yup.string(),
    thirdRange_Team: Yup.number()
      .moreThan(
        Yup.ref("secondRange_Team"),
        "Faixa de volume inferior a segunda"
      )
      .required("Faixa de volume 3 é obrigatória"),
    fourthRange_Team: Yup.number()
      .moreThan(
        Yup.ref("thirdRange_Team"),
        "Faixa de volume inferior a terceira"
      )
      .required("Faixa de volume 4 é obrigatória"),
    price: Yup.array().of(
      Yup.object().shape({
        firstRange_Price: Yup.number().required("Obrigatório"),
        secondRange_Price: Yup.number().test(
          "checkbox",
          "Obrigatório",
          (val: any) => (!checked ? typeof val === "number" : true)
        ),
        thirdRange_Price: Yup.number().test(
          "checkbox",
          "Obrigatório",
          (val: any) => (!checked ? typeof val === "number" : true)
        ), //.required("Obrigatório"),
        fourthRange_Price: Yup.number().test(
          "checkbox",
          "Obrigatório",
          (val: any) => (!checked ? typeof val === "number" : true)
        ), //.required("Obrigatório"),
        outsideRange_Price: Yup.number().test(
          "checkbox",
          "Obrigatório",
          (val: any) => (!checked ? typeof val === "number" : true)
        ), //.required("Obrigatório"),
      })
    ),
  });

  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      name_Team: state ? state.name_Team : "",
      firstRange_Team: state ? state.firstRange_Team : "",
      secondRange_Team: state ? state.secondRange_Team : "",
      thirdRange_Team: state ? state.thirdRange_Team : "",
      fourthRange_Team: state ? state.fourthRange_Team : "",
      firstDiscount: state ? state.firstDiscount : "",
      secondDiscount: state ? state.secondDiscount : "",
      thirdDiscount: state ? state.thirdDiscount : "",
      fourthDiscount: state ? state.fourthDiscount : "",
      observations_Customer: state
        ? state.values?.observations_Customer || ""
        : "",
      price: state ? state.price : [],
      priceAdd: "default",
    },
    validateOnChange: true,
    // isInitialValid: false,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: () => {
      // TODO: CADASTRAR LENTE
    },
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (formik.isValid) {
      if (checked && values.price) {
        for (let i = 0; i < values.price?.length; i++) {
          values.price[i].secondRange_Price = values.price[i].firstRange_Price;
          values.price[i].thirdRange_Price = values.price[i].firstRange_Price;
          values.price[i].fourthRange_Price = values.price[i].firstRange_Price;
          values.price[i].outsideRange_Price = values.price[i].firstRange_Price;
        }
      } else {
        for (let i = 0; i < values.price?.length; i++) {
          values.price[i].secondRange_Price =
            values.price[i].firstRange_Price *
            (1 - values.secondDiscount / 100);
          values.price[i].thirdRange_Price =
            values.price[i].firstRange_Price * (1 - values.thirdDiscount / 100);
          values.price[i].fourthRange_Price =
            values.price[i].firstRange_Price *
            (1 - values.fourthDiscount / 100);
          values.price[i].outsideRange_Price =
            values.price[i].firstRange_Price *
            (1 - values.fourthDiscount / 100);
        }
      }
      handleGroupData(values);
      if (values.price?.length) props.setValid(true);
      else props.setValid(false);
    } else props.setValid(false);
  }, [values, props.state, formik.isValid, checked]);

  useEffect(() => {
    if (checked) formik.validateForm();
  }, [checked]);

  useEffect(() => {
    if (state && state.price?.length) {
      let arrayFilter: any[] = productDataAux;
      state.price.forEach((element: any) => {
        arrayFilter = arrayFilter.filter(
          (res: any) => res.id_Lens !== element.idLens_Price
        );
      });
      setProductData(arrayFilter);
    } else {
      setProductData(productDataAux);
    }
  }, [productDataAux]);

  useEffect(() => {
    productService
      .getAll()
      .then((data: any) => {
        setProductDataAux(data.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (state)
      if (state.values?.observations_Customer?.length) setOnRegister(true);
  }, [state]);

  async function addProduct() {
    let arrayFilter: any[] = productData;
    arrayFilter = arrayFilter.filter(
      (res: any) => res.id_Lens !== productPriceData.id_Lens
    );
    setProductData(arrayFilter);
    setProducPricetData({
      name_Lens: "default",
      id_Lens: "",
    });
  }
  async function removeProduct(item: any) {
    let arrayFilter: any[] = productData;
    arrayFilter.push({ id_Lens: item.idLens_Price, name_Lens: item.name_Lens });
    setProductData(arrayFilter);
    setProducPricetData({
      name_Lens: "default",
      id_Lens: "",
    });
  }

  function buttonAdd(arrayHelpers: any) {
    if (productPriceData.name_Lens !== "default")
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            arrayHelpers.push({
              idLens_Price: productPriceData.id_Lens,
              name_Lens: productPriceData.name_Lens,
              firstRange_Price: "",
              secondRange_Price: "",
              thirdRange_Price: "",
              fourthRange_Price: "",
              outsideRange_Price: "",
              closed_Price: true,
            });
            addProduct();
          }}
        >
          Adicionar produto
        </Button>
      );
  }

  return (
    <Card>
      <CardHeader
        sx={{
          background: "#004A78",
          color: "#fff",
          paddingLeft: "30px",
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
        title="Dados do Grupo"
      />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Container>
              <Grid container columnSpacing={4} rowSpacing={2}>
                {onRegister ? (
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      multiline
                      type="text"
                      color="secondary"
                      variant="outlined"
                      label="Observações*"
                      {...getFieldProps("observations_Customer")}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </Grid>
                ) : undefined}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    label="Nome do grupo*"
                    {...getFieldProps("name_Team")}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={Boolean(touched.name_Team && errors.name_Team)}
                    helperText={touched.name_Team && errors.name_Team}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Faixas de volume
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    color="secondary"
                    variant="outlined"
                    label="Faixa de volume 1"
                    {...getFieldProps("firstRange_Team")}
                    error={Boolean(
                      touched.firstRange_Team && errors.firstRange_Team
                    )}
                    helperText={
                      touched.firstRange_Team && errors.firstRange_Team
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    color="secondary"
                    variant="outlined"
                    label="Faixa de volume 2"
                    {...getFieldProps("secondRange_Team")}
                    helperText={
                      touched.secondRange_Team && errors.secondRange_Team
                    }
                    error={Boolean(
                      touched.secondRange_Team && errors.secondRange_Team
                    )}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    color="secondary"
                    variant="outlined"
                    label="Faixa de volume 3"
                    {...getFieldProps("thirdRange_Team")}
                    helperText={
                      touched.thirdRange_Team && errors.thirdRange_Team
                    }
                    error={Boolean(
                      touched.thirdRange_Team && errors.thirdRange_Team
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    color="secondary"
                    variant="outlined"
                    label="Faixa de volume 4"
                    {...getFieldProps("fourthRange_Team")}
                    helperText={
                      touched.fourthRange_Team && errors.fourthRange_Team
                    }
                    error={Boolean(
                      touched.fourthRange_Team && errors.fourthRange_Team
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Descontos
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    color="secondary"
                    disabled
                    variant="outlined"
                    label="Desconto Faixa de volume 1"
                    {...getFieldProps("firstDiscount")}
                    error={Boolean(
                      touched.firstDiscount && errors.firstDiscount
                    )}
                    helperText={touched.firstDiscount && errors.firstDiscount}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    color="secondary"
                    variant="outlined"
                    label="Desconto Faixa de volume 2"
                    {...getFieldProps("secondDiscount")}
                    helperText={touched.secondDiscount && errors.secondDiscount}
                    error={Boolean(
                      touched.secondDiscount && errors.secondDiscount
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    color="secondary"
                    variant="outlined"
                    label="Desconto Faixa de volume 3"
                    {...getFieldProps("thirdDiscount")}
                    helperText={touched.thirdDiscount && errors.thirdDiscount}
                    error={Boolean(
                      touched.thirdDiscount && errors.thirdDiscount
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    color="secondary"
                    variant="outlined"
                    label="Desconto Faixa de volume 4"
                    {...getFieldProps("fourthDiscount")}
                    helperText={touched.fourthDiscount && errors.fourthDiscount}
                    error={Boolean(
                      touched.fourthDiscount && errors.fourthDiscount
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Produtos
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="product" color="secondary">
                      Produtos *
                    </InputLabel>
                    <Select
                      fullWidth
                      color="secondary"
                      labelId="product"
                      label="Produtos *"
                      value={productPriceData.name_Lens}
                      error={Boolean(touched.priceAdd && errors.priceAdd)}
                    >
                      <MenuItem disabled value="default">
                        <em>Selecionar...</em>
                      </MenuItem>
                      {productData?.length ? (
                        productData.map((row: any) => {
                          const { name_Lens, id_Lens } = row;
                          return (
                            <MenuItem
                              key={id_Lens}
                              value={name_Lens}
                              onClick={() => setProducPricetData(row)}
                            >
                              {name_Lens}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value="Feminino">
                          Nenhum item encontrado...
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <FieldArray
                  name="price"
                  render={(arrayHelpers) => {
                    const price = values.price;
                    return (
                      <>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                              />
                            }
                            label="Faixas de preço iguais"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {buttonAdd(arrayHelpers)}
                        </Grid>

                        {price && price?.length > 0 ? (
                          price.map((priceData: any, index: any) => (
                            <Grid item container xs={6} key={index} spacing={3}>
                              <Grid item xs={8}>
                                <Typography
                                  mt={1}
                                  variant="body1"
                                  noWrap
                                  sx={{ color: "#909090" }}
                                >
                                  {priceData.name_Lens}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                    removeProduct(priceData);
                                  }}
                                >
                                  Remover
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  type="number"
                                  label="Preço"
                                  {...getFieldProps(
                                    checked
                                      ? `price[${index}].firstRange_Price`
                                      : `price[${index}].firstRange_Price`
                                  )}
                                  variant="outlined"
                                  color="secondary"
                                  fullWidth={true}
                                  //@ts-ignore
                                  helperText={
                                    touched.price &&
                                    touched.price[index]?.firstRange_Price &&
                                    errors.price &&
                                    errors.price[index]?.firstRange_Price
                                  }
                                  //@ts-ignore
                                  error={Boolean(
                                    touched.price &&
                                      touched.price[index]?.firstRange_Price &&
                                      errors.price &&
                                      errors.price[index]?.firstRange_Price
                                  )}
                                />
                              </Grid>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Typography
                              mt={1}
                              variant="body1"
                              noWrap
                              sx={{ color: "#909090" }}
                            >
                              Nenhum produto registrado
                            </Typography>
                          </Grid>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
            </Container>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
