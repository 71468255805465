/* eslint-disable camelcase */
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import calendarOutline from "@iconify/icons-eva/calendar-outline";
import downloadOutline from "@iconify/icons-eva/download-outline";
// material
import {
  Grid,
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  CardActions,
  CardContent,
  IconButton,
  TablePagination,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
// components
import Page from "../../../../components/Page";

import financialService from "../services/financialService";
import AlertMessage from "../../../../components/alert/alert";
import ClientsListHead from "../../clients/components/ClientsListHead";

const TABLE_HEAD = [
  { id: "cnpj_JuridicalPerson", label: "CNPJ", alignRight: false },
  { id: "city_Address", label: "Cidade", alignRight: false },
  { id: "contmaticCode_Customer", label: "Cliente", alignRight: false },
  { id: "name_PaymentType", label: "Cond Pgto", alignRight: false },
  { id: "sigaCode_Lens", label: "Código", alignRight: false },
  { id: "name_Lens", label: "Descrição", alignRight: false },
  { id: "billingPlace_Order", label: "Filial", alignRight: false },
  { id: "name_Customer", label: "Nome", alignRight: false },
  { id: "price", label: "Preço Médio", alignRight: false },
  { id: "itemCount", label: "Quantidade", alignRight: false },
  { id: "totalValueFormatted", label: "Total", alignRight: false },
  {
    id: "totalValueWithDiscountFormatted",
    label: "Total Com desconto",
    alignRight: false,
  },
  { id: "idDesignCount", label: "Recortadas", alignRight: false },
  { id: "state_Address", label: "UF", alignRight: false },
];

export default function ReportFinancial() {
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [dataFilter, setDataFilter]: any = useState([]);
  const [dataSelect, setDataSelect]: any = useState("0");
  const [showError, setError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [notFirstQuery, setNotFirstQuery] = useState(false);
  const [queryStartEnd, setQueryStartEnd] = useState([
    "0",
    rowsPerPage.toString(),
  ]);
  const [clientsComplete, setClientsComplete]: any = useState([]);
  const [selectedClient, setSelectedClient]: any = useState(null); // Estado do cliente selecionado

  const [params, setParams]: any = useState({
    startDate: undefined,
    endDate: undefined,
  });
  useEffect(() => {
    financialService
      .getAll("0", "50", params)
      .then((data: any) => setDataFilter(data.data))
      .catch((error: any) => console.error(error));
  }, []);

  useEffect(() => {
    setPage(0);
    financialService
      .getAllOrders(dataSelect)
      .then((data: any) => setData(data.data))
      .catch((error: any) => console.error(error));
  }, [dataSelect]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        notFirstQuery
          ? financialService
              .getAll(queryStartEnd[0], queryStartEnd[1], params)
              .then((data: any) => setData(data.data))
              .catch((error: any) => console.error(error))
          : setNotFirstQuery(true),
      500
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: any) => {
    setDataSelect(event.target.value);
  };

  const filteredData =
    data && selectedClient
      ? data.filter(
          (row: any) => row.name_Customer === selectedClient?.name_Customer
        )
      : data;

  useEffect(() => {
    const uniqueOptions =
      data && data.length > 0
        ? Array.from(
            new Map(
              data.map((item: any) => [item.name_Customer, item])
            ).values()
          )
        : [];
    setClientsComplete(uniqueOptions);
  }, [data]);

  return (
    <Page title="Report Financeiro | Optools">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Financeiro
          </Typography>
        </Stack>

        <Stack
          direction={["column", "row"]}
          justifyContent="flex-start"
          alignItems="flex-end"
          my={4}
          spacing={2}
        >
          <FormControl sx={{ pr: 2, minWidth: 380 }}>
            <InputLabel id="demo-select-small-label">
              Data de fechamento
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={dataSelect}
              label="Escolha o fechamento"
              onChange={handleChange}
            >
              <MenuItem value={"0"}>Ordens em aberto</MenuItem>
              {dataFilter.map((item: any, index: any) => (
                <MenuItem value={item.id_Billing} key={index}>
                  Fechamento {item.id_Billing} -{" "}
                  {item.serverRegisterDate_Billing}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Autocomplete
            disablePortal
            options={clientsComplete}
            getOptionLabel={(option: any) => option.name_Customer}
            sx={{ width: 380, ml: 2 }}
            onChange={(event, value) => setSelectedClient(value)} // Atualiza o estado
            renderInput={(params) => (
              <TextField {...params} label="Nome do cliente" />
            )}
          />
        </Stack>
        <Card>
          <TableContainer>
            <Table>
              <ClientsListHead headLabel={TABLE_HEAD} />
              <TableBody>
                {filteredData.length ? (
                  (rowsPerPage > 0
                    ? filteredData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredData
                  ).map((row: any, index: any) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={false}
                        aria-checked={false}
                      >
                        {TABLE_HEAD.map((headFlag, index) => (
                          <TableCell component="th" scope="row" key={index}>
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ color: "#004A78", fontSize: "14px" }}
                            >
                              {row[headFlag.id]}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Stack
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Icon
                            icon={inboxOutline}
                            width="56px"
                            color="#909090"
                          />
                          <Typography
                            mt={1}
                            variant="body1"
                            noWrap
                            sx={{ color: "#909090" }}
                          >
                            Não encontrado...
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            page={page}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={(rowsInfo) => {
              const totalPages = Math.ceil(
                rowsInfo.count / rowsPerPage
              ).toString();
              return (
                <>
                  {`Página: ${page + 1}${`/${
                    totalPages === "0" ? "1" : totalPages
                  }`}`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {`Total: ${rowsInfo.count - 1}`}
                </>
              );
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {showSuccess && (
          <AlertMessage open={true} message="Sucesso ao gerar fechamento!" />
        )}
        {showError && (
          <AlertMessage open={true} message="Erro ao gerar fechamento" />
        )}
      </Container>
    </Page>
  );
}
