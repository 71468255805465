import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// material
import { styled } from "@mui/system";
import {
  Box,
  Stack,
  Toolbar,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import stageService from "../../operational/services/stageService";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  float: "left",
  width: "100%",
  display: "flex",
  padding: theme.spacing(0, 0, 0, 0),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 250, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const DatePicker = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 192, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

OrdersListToolbar.propTypes = {
  filterReset: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterDate: PropTypes.func,
  onFilterStatus: PropTypes.func,
  stage: PropTypes.string,
};

export default function OrdersListToolbar({
  filterName,
  filterReset,
  onFilterName,
  onFilterDate,
  onFilterStatus,
  stage,
}: any) {
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [stagesData, setStagesData]: any = useState([]);
  const stages = async () => {
    const data = await stageService.getAll();
    setStagesData(data?.data ? data.data : []);
    console.log(stagesData);
  };

  useEffect(() => {
    stages();
  }, []);

  return (
    <Grid
      container
      alignItems={"flex-end"}
      justifyContent={"flex-start"}
      spacing={1}
      rowGap={1}
      columnGap={1}
      py={2}
    >
      <Grid xs={4}>
        <Stack sx={{ width: "100%" }}>
          <SearchStyle
            type="text"
            value={filterName}
            placeholder="Pesquisar..."
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={(element: any) =>
              onFilterName(element.target.value?.toUpperCase())
            }
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
        </Stack>
      </Grid>
      <Grid xs={3}>
        <Typography variant="subtitle2" color="secondary">
          Data inicial:
        </Typography>
        <DatePicker
          type="date"
          value={date1}
          onChange={(event: any) => {
            const value = event.target.value;

            setDate1(value);
            onFilterDate(
              value.split("-").reverse().join("/"),
              date2.split("-").reverse().join("/")
            );
          }}
        />
      </Grid>
      <Grid xs={3}>
        <Typography variant="subtitle2" color="secondary">
          Data final:
        </Typography>
        <DatePicker
          type="date"
          value={date2}
          onChange={(event: any) => {
            const value = event.target.value;

            setDate2(value);
            onFilterDate(
              date1.split("-").reverse().join("/"),
              value.split("-").reverse().join("/")
            );
          }}
        />
      </Grid>
      <Grid xs={1}>
        <Button
          sx={{ ml: [0, 2], mt: [1, 2] }}
          color="inherit"
          onClick={() => {
            setDate1("");
            setDate2("");
            filterReset();
          }}
        >
          <HighlightOffIcon sx={{ color: "#A9A9A9" }} />
          <Typography variant="body2" color="#878787" sx={{ ml: "3px" }}>
            LIMPAR FILTROS
          </Typography>
        </Button>
      </Grid>
      <Grid xs={4}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="type" color="secondary">
            Status
          </InputLabel>
          <Select
            fullWidth
            labelId="type"
            color="secondary"
            label="Status"
            value={stage || "ALL"}
            sx={{ borderRadius: "12px" }}
            onChange={(event) => onFilterStatus(event.target.value)}
          >
            <MenuItem value="ALL">Todas</MenuItem>
            <MenuItem value="CADASTRADA">CADASTRADA</MenuItem>
            <MenuItem value="RECEBIDA">RECEBIDA</MenuItem>
            {stagesData?.length > 0 &&
              stagesData.map((item: any, index: any) => (
                <MenuItem value={item?.name} key={index}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
