import http from "../../../services/http-common";

class OrderService {
  // eslint-disable-next-line class-methods-use-this
  async saveOrder(data: any) {
    try {
      const order = {
        code_Order: data.code_Order,
        idMaker_Order: data.idMaker_Order.toString(),
        lensesAmount_Order: data.lensesAmount_Order,
        idBlock_Order: data.idBlock_Order.toString() || "",
        idPhotosensitivity_Order: data.idPhotosensitivity_Order.toString(),
        idDesign_Order: data.idDesign_Order.toString(),
        idLens_Order: data.idLens_Order.toString(),
        idMaterial_Order: data.idMaterial_Order.toString(),
        idFocus_Order: data.idFocus_Order.toString(),

        // Prescription
        rightSpherical_Order: data.rightSpherical_Order || "",
        rightCylindrical_Order: data.rightCylindrical_Order || "",
        rightAxis_Order: data.rightAxis_Order || "",
        rightAddition_Order: data.rightAddition_Order || "",
        leftSpherical_Order: data.leftSpherical_Order || "",
        leftCylindrical_Order: data.leftCylindrical_Order || "",
        leftAxis_Order: data.leftAxis_Order || "",
        leftAddition_Order: data.leftAddition_Order || "",
        os_clientNumber: data.os_clientNumber || "",
        // Extra
        observation_Order: data.observation_Order?.toUpperCase() || "",
      };
      return http.post("/order/customer", order);
    } catch (error) {
      console.log(error);
    }
  }

  async updateOrder(data: any) {
    try {
      console.log(data);
      const order = {
        idMaker_Order: data.idMaker_Order.toString(),
        lensesAmount_Order: data.lensesAmount_Order,
        idBlock_Order: data.idBlock_Order.toString() || "",
        idPhotosensitivity_Order: data.idPhotosensitivity_Order.toString(),
        idDesign_Order: data.idDesign_Order.toString(),
        idLens_Order: data.idLens_Order.toString(),
        idMaterial_Order: data.idMaterial_Order.toString(),
        idFocus_Order: data.idFocus_Order.toString(),

        // Prescription
        rightSpherical_Order: data.rightSpherical_Order,
        rightCylindrical_Order: data.rightCylindrical_Order,
        rightAxis_Order: data.rightAxis_Order,
        rightAddition_Order: data.rightAddition_Order,
        leftSpherical_Order: data.leftSpherical_Order,
        leftCylindrical_Order: data.leftCylindrical_Order,
        leftAxis_Order: data.leftAxis_Order,
        leftAddition_Order: data.leftAddition_Order,
        os_clientNumber: data.os_clientNumber || "",
        // Extra
        observation_Order: data.observation_Order?.toUpperCase() || "",
      };

      console.log(order);

      return http.put(`/order/update/${data.code_Order}`, order);
    } catch (error) {
      console.log(error);
    }
  }

  getTag(orderCode: string | number) {
    return http.get<any, { base64: string }>(
      `order/label/printing/${orderCode}`
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(offset: string, limit: string, params: any) {
    const parse: any = localStorage.getItem("user");
    const user = JSON.parse(parse);

    return http.get(`order/customer/${user?.idCustomer}/${offset}/${limit}`, {
      params: params,
    });
  }

  getReport(
    idCustomer: string,
    prePaid: boolean,
    password: string,
    startDate?: string,
    endDate?: string
  ) {
    const today = new Date();
    const priorDate = new Date().setDate(today.getDate() - 30);
    const defaultStart = new Date(priorDate).toLocaleDateString("pt-br");

    const type = prePaid ? "prepaid" : "postpaid";
    const data = {
      costReportPassword_Customer: password,
      startDate: startDate || defaultStart,
      endDate: endDate || "01/01/2050",
    };
    const query = `customer/${type}/cost/report/${idCustomer}`;

    return http.post(query, data);
  }

  addCredit(idCustomer: number, value: number) {
    const credit = {
      idCustomer_Credit: idCustomer,
      value_Credit: value,
    };

    return http.post("credit/", credit);
  }

  async validate(orderCode: string) {
    return http.get(`order/code/checker/${orderCode}`);
  }

  getSelect(type: string) {
    return http.get(`/${type}/find`);
  }

  getLens() {
    const parse: any = localStorage.getItem("user");
    const user = JSON.parse(parse);

    return http.get(`/team/lens/${user?.idCustomer}`);
  }

  getById(id: string) {
    return http.get(`/order/${id}`);
  }

  getActions(id: string) {
    return http.get(`/order/actions/${id}`);
  }
}

export default new OrderService();
