/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import * as Yup from "yup";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Card,
  Grid,
  Select,
  MenuItem,
  TextField,
  Container,
  InputLabel,
  CardHeader,
  Typography,
  FormControl,
  CardContent,
  InputAdornment,
} from "@mui/material";

import ActionCard from "./ActionCard";
import { useData } from "../../../contexts/client";

export default function FormDataOrder(props: any) {
  const { state }: any = useLocation();
  const [ready, setReady] = useState(false);

  const { handleOrderData, orderData } = useData();
  const RegisterSchema = Yup.object().shape({
    // Lens Data
    lensesBrand_Order: Yup.string(),
    idMaker_Order: Yup.string().required("Campo obrigatório"),
    lensesAmount_Order: Yup.string().required("Campo obrigatório"),
    idBlock_Order: Yup.string(),
    idPhotosensitivity_Order: Yup.string().required("Campo obrigatório"),
    idDesign_Order: Yup.string().required("Campo obrigatório"),
    idLens_Order: Yup.string().required("Campo obrigatório"),
    idMaterial_Order: Yup.string().required("Campo obrigatório"),
    idFocus_Order: Yup.string().required("Campo obrigatório"),

    // Prescription
    rightSpherical_Order: Yup.string(),
    rightCylindrical_Order: Yup.string(),
    rightAxis_Order: Yup.string(),
    leftSpherical_Order: Yup.string(),
    leftCylindrical_Order: Yup.string(),
    leftAxis_Order: Yup.string(),
    leftAddition_Order: Yup.string(),
    rightAddition_Order: Yup.string(),

    // Extra
    observation_Order: Yup.string(),
  });

  let formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      // Lens Data
      lensesBrand_Order: state ? state.lensesBrand_Order : "",
      idMaker_Order: state?.maker.id || "",
      lensesAmount_Order: state?.lensesAmount_Order || "",
      idBlock_Order: state?.block.id || 7,
      idPhotosensitivity_Order: state?.photosensitivity.id || "",
      idDesign_Order: state?.design?.id || "",
      idLens_Order: state?.lens.id || "",
      idMaterial_Order: state?.material.id || "",
      idFocus_Order: state?.focus.id || "",

      // Prescription
      rightSpherical_Order: state ? state.rightSpherical_Order : "",
      rightCylindrical_Order: state ? state.rightCylindrical_Order : "",
      rightAxis_Order: state ? state.rightAxis_Order : "",
      leftSpherical_Order: state ? state.leftSpherical_Order : "",
      leftCylindrical_Order: state ? state.leftCylindrical_Order : "",
      leftAxis_Order: state ? state.leftAxis_Order : "",
      leftAddition_Order: state ? state.leftAddition_Order : "",
      rightAddition_Order: state ? state.rightAddition_Order : "",
      os_clientNumber: state ? state.os_clientNumber : "",

      // Extra
      observation_Order: state ? state.observation_Order : "",
    },
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: () => {
      // TODO: CADASTRAR LENTE
    },
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (formik.isValid) {
      props.setValid(true);
      handleOrderData({ ...values, code_Order: orderData.code_Order });
    } else if (props.isValid) props.setValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid, values]);

  useEffect(() => {
    if (props.selects !== undefined) setTimeout(() => setReady(true), 1500);
  }, [props]);

  return (
    <>
      <Card>
        <CardHeader
          sx={{
            background: "#004A78",
            color: "#fff",
            paddingLeft: "30px",
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
          title="Identificação das Lentes"
        />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Container>
                {ready ? (
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="prodserv">
                          Tratamento*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="prodserv"
                          label="Tratamento*"
                          disabled={!!state?.actions[0]?.date}
                          color="secondary"
                          {...getFieldProps("idLens_Order")}
                          error={Boolean(
                            touched.idLens_Order && errors.idLens_Order
                          )}
                        >
                          {props.selects?.lens?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="quant">
                          Quantidade*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="quant"
                          label="Quantidade*"
                          disabled={!!state?.actions[0]?.date}
                          color="secondary"
                          {...getFieldProps("lensesAmount_Order")}
                          error={Boolean(
                            touched.lensesAmount_Order &&
                              errors.lensesAmount_Order
                          )}
                        >
                          <MenuItem value="1">0.5 par</MenuItem>
                          <MenuItem value="2">1.0 par</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="maker">
                          Fabricante*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="maker"
                          label="Fabricante*"
                          disabled={!!state?.actions[0]?.date}
                          color="secondary"
                          {...getFieldProps("idMaker_Order")}
                          error={Boolean(
                            touched.idMaker_Order && errors.idMaker_Order
                          )}
                        >
                          {props.selects?.maker?.map((item: any) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="focus">
                          Tipo de lente*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="focus"
                          label="Tipo de lente*"
                          disabled={!!state?.actions[0]?.date}
                          color="secondary"
                          {...getFieldProps("idFocus_Order")}
                          error={Boolean(
                            touched.idFocus_Order && errors.idFocus_Order
                          )}
                        >
                          {props.selects?.focus?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="block">
                          Observação Lente/Serviço
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="block"
                          label="Observação Lente/Serviço"
                          disabled={!!state?.actions[0]?.date}
                          color="secondary"
                          {...getFieldProps("idBlock_Order")}
                          error={Boolean(
                            touched.idBlock_Order && errors.idBlock_Order
                          )}
                        >
                          {props.selects?.block?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="photo">
                          Fotossensibilidade*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="photo"
                          label="Fotossensibilidade*"
                          disabled={!!state?.actions[0]?.date}
                          color="secondary"
                          {...getFieldProps("idPhotosensitivity_Order")}
                          error={Boolean(
                            touched.idPhotosensitivity_Order &&
                              errors.idPhotosensitivity_Order
                          )}
                        >
                          {props.selects?.photosensitivity?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="type">
                          Inteira/Recortada*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="type"
                          label="Inteira/Recortada*"
                          disabled={!!state?.actions[0]?.date}
                          color="secondary"
                          {...getFieldProps("idDesign_Order")}
                          error={Boolean(
                            touched.idDesign_Order && errors.idDesign_Order
                          )}
                        >
                          {props.selects?.design?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="material">
                          Material*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="material"
                          label="Material*"
                          disabled={!!state?.actions[0]?.date}
                          color="secondary"
                          {...getFieldProps("idMaterial_Order")}
                          error={Boolean(
                            touched.idMaterial_Order && errors.idMaterial_Order
                          )}
                        >
                          {props.selects?.material?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="OS do Cliente"
                        {...getFieldProps("os_clientNumber")}
                        helperText={
                          touched.os_clientNumber && errors.os_clientNumber
                        }
                        error={Boolean(
                          touched.os_clientNumber && errors.os_clientNumber
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Prescrição
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        disabled={!!state?.actions[0]?.date}
                        color="secondary"
                        variant="outlined"
                        label="Esférico"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("rightSpherical_Order")}
                        helperText={
                          touched.rightSpherical_Order &&
                          errors.rightSpherical_Order
                        }
                        error={Boolean(
                          touched.rightSpherical_Order &&
                            errors.rightSpherical_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        disabled={!!state?.actions[0]?.date}
                        color="secondary"
                        variant="outlined"
                        label="Cilíndrico"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("rightCylindrical_Order")}
                        helperText={
                          touched.rightCylindrical_Order &&
                          errors.rightCylindrical_Order
                        }
                        error={Boolean(
                          touched.rightCylindrical_Order &&
                            errors.rightCylindrical_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Eixo"
                        disabled={!!state?.actions[0]?.date}
                        color="secondary"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("rightAxis_Order")}
                        helperText={
                          touched.rightAxis_Order && errors.rightAxis_Order
                        }
                        error={Boolean(
                          touched.rightAxis_Order && errors.rightAxis_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Adição"
                        disabled={!!state?.actions[0]?.date}
                        color="secondary"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("rightAddition_Order")}
                        helperText={
                          touched.rightAddition_Order &&
                          errors.rightAddition_Order
                        }
                        error={Boolean(
                          touched.rightAddition_Order &&
                            errors.rightAddition_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        disabled={!!state?.actions[0]?.date}
                        color="secondary"
                        variant="outlined"
                        label="Esférico"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">L</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("leftSpherical_Order")}
                        helperText={
                          touched.leftSpherical_Order &&
                          errors.leftSpherical_Order
                        }
                        error={Boolean(
                          touched.leftSpherical_Order &&
                            errors.leftSpherical_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        disabled={!!state?.actions[0]?.date}
                        color="secondary"
                        variant="outlined"
                        label="Cilíndrico"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">L</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("leftCylindrical_Order")}
                        helperText={
                          touched.leftCylindrical_Order &&
                          errors.leftCylindrical_Order
                        }
                        error={Boolean(
                          touched.leftCylindrical_Order &&
                            errors.leftCylindrical_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        disabled={!!state?.actions[0]?.date}
                        color="secondary"
                        variant="outlined"
                        label="Eixo"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">L</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("leftAxis_Order")}
                        helperText={
                          touched.leftAxis_Order && errors.leftAxis_Order
                        }
                        error={Boolean(
                          touched.leftAxis_Order && errors.leftAxis_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        disabled={!!state?.actions[0]?.date}
                        color="secondary"
                        variant="outlined"
                        label="Adição"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">L</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("leftAddition_Order")}
                        helperText={
                          touched.leftAddition_Order &&
                          errors.leftAddition_Order
                        }
                        error={Boolean(
                          touched.leftAddition_Order &&
                            errors.leftAddition_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Observações
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Observações"
                        disabled={!!state?.actions[0]?.date}
                        {...getFieldProps("observation_Order")}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        helperText={
                          touched.observation_Order && errors.observation_Order
                        }
                        error={Boolean(
                          touched.observation_Order && errors.observation_Order
                        )}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Typography sx={{ textAlign: "center", paddingY: "56px" }}>
                    Carregando...
                  </Typography>
                )}
              </Container>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
      {state ? <ActionCard actions={state?.actions} /> : undefined}
    </>
  );
}
